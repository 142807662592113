import React from 'react'
import _ from 'lodash'
import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import TIMEOUTS from '@core/constants/timeouts'
import { ItemStatusMap } from '@core/types'

import { createItemActions } from './content-version-actions'
import type {
  Actions,
  CommonSelectProps,
  FieldError,
  Instance,
  State,
} from './content-version-types'
import { updateItems } from './content-version-update'

type DebounceMapBySelector = Map<string, _.DebouncedFunc<any>>

const debounceMapByItem = new Map<string, DebounceMapBySelector>()

export const flushFields = (rootId: string) => {
  debounceMapByItem.get(rootId)?.forEach((debounced) => {
    debounced.flush()
  })
}

export const useContentVersionState = createWithEqualityFn(
  immer<State & Actions>((set, get) => {
    const setInstance: Actions['setInstance'] = (rootId, cb) => {
      set((prev) => {
        const instance = prev.instances[rootId]

        if (!instance) return

        cb.apply(null, [instance])
      })
    }

    const _buildMetricsAndCommit = async (instance: Instance) => {
      const { rootId } = instance

      const items = _.cloneDeep(instance.items)
      const itemChanges = _.cloneDeep(instance.itemChanges)

      const { buildMetrics, onBeforeCommit, checkUnsavedChanges } = instance.options ?? {}

      const changesToApply = await buildMetrics(instance.items)

      const unsavedChanges = items.root.project ? checkUnsavedChanges(items, 'update') : true

      if (!changesToApply?.length) return

      setInstance(rootId, (prev) => {
        changesToApply.forEach(({ id, path, value }) => {
          _.set(prev.itemChanges, [id, path.split('.')[0]], true)
          _.set(prev.items[id].currentContent, path, value)
        })

        prev.itemChanges = {}
        prev.unsavedChanges = unsavedChanges
      })

      changesToApply.forEach(({ id, path, value }) => {
        _.set(itemChanges, [id, path.split('.')[0]], true)
        _.set(items[id].currentContent, path, value)
      })

      await updateItems(rootId, onBeforeCommit ? onBeforeCommit(items) : items, itemChanges)

      setInstance(rootId, (prev) => {
        prev.step = 'noChanges'
      })
    }

    const createUpdater = (): Instance['updater'] => {
      return _.debounce(
        async (instance) => {
          const { rootId, validator } = instance

          // flush fields
          flushFields(rootId)

          const validation = validator.flush()!

          setInstance(rootId, (prev) => {
            prev.step = 'saving'
            prev.validation = validation

            // hide the alert after first user interaction
            prev.unsavedChangesFromLastTime = false
          })

          await _buildMetricsAndCommit(instance)

          setInstance(rootId, (prev) => {
            // could have another updates in the meantime
            if (prev.step === 'saving') {
              prev.step = 'saved'
            }
          })

          const hasErrors = validation.totalErrors > 0

          return { hasErrors, validation }
        },
        1000,
        { maxWait: 2000 },
      )
    }

    const createValidator = (): Instance['validator'] => {
      return _.debounce(
        (instance) => {
          const { items, options, rootId } = instance

          const { validate } = options ?? {}

          if (!validate) {
            return []
          }

          const validation = validate(items)

          setInstance(rootId, (prev) => {
            prev.validation = validation
          })

          return validation
        },
        300,
        { maxWait: 1000 },
      )
    }

    return {
      instances: {},

      initManager(rootId, items, options) {
        const prevInstance = get().instances[rootId]

        const validation = options?.validate?.(items) ?? {
          errors: {},
          totalErrors: 0,
          totalItemsWithError: 0,
        }

        const unsavedChanges = items.root.project
          ? options.checkUnsavedChanges(items, 'update')
          : true

        // only update the items
        if (prevInstance) {
          set((prev) => {
            prev.instances[rootId].items = items

            prev.instances[rootId].items.root.currentContent = {
              ...prev.instances[rootId].items.root.currentContent,
              qualityMetrics: prev.instances[rootId].items.root.currentContent.qualityMetrics,
            }

            prev.instances[rootId].validation = validation
            prev.instances[rootId].unsavedChangesFromLastTime = false
            prev.instances[rootId].unsavedChanges = unsavedChanges
          })

          return { instance: get().instances[rootId], prevInstance }
        }

        const unsavedChangesFromLastTime = items.root.project
          ? options.checkUnsavedChanges(items, 'init')
          : false

        const updater = createUpdater()

        const instance: Instance = {
          rootKey: 0,
          rootId,
          options,
          items: _.cloneDeep(items),
          step: 'noChanges',

          unsavedChangesFromLastTime,

          unsavedChanges,

          updater,
          validator: createValidator(),

          actions: createItemActions(rootId, get, set),

          itemChanges: {},

          validation,

          flush: () => {
            flushFields(rootId)

            return updater.flush()
          },
        }

        set((prev) => {
          prev.instances[rootId] = instance
        })

        if (!options.skipInitialCommit) {
          _buildMetricsAndCommit(instance)
        }

        return { instance, prevInstance }
      },

      async unmount(rootId) {
        const instance = get().instances[rootId]

        if (!instance) return

        // finish any pending commit
        await instance.flush()

        // delete/clear the instance
        set((prev) => {
          delete prev.instances[rootId]
        })

        debounceMapByItem.delete(rootId)
      },

      setInstance,

      setItem(rootId, itemId, value, shouldCommit = true) {
        setInstance(rootId, (prev) => {
          const oldItem = prev.items[itemId]
          const newItem = typeof value === 'function' ? value(oldItem) : value

          prev.items[itemId] = newItem
        })

        const instance = get().instances[rootId]

        if (shouldCommit && instance) {
          _buildMetricsAndCommit(instance)

          // trigger validation
          instance.validator(instance)
        }
      },

      updateItem(selector, value) {
        const instance = get().instances[selector.rootId]

        if (!instance) {
          console.warn('the component was unmounted, aborting update', { selector, value })
          return
        }

        const item = instance.items?.[selector.id]

        if (!item) {
          console.warn('the item was not initialized, aborting update', { selector, value })
          return
        }

        const before = _.get(item.currentContent, selector.path)
        let after = value

        if (typeof value === 'function') {
          after = value(before)
        }

        if (before === after || selector?.compare?.(before, after)) return

        set((prev) => {
          prev.instances[selector.rootId].step = 'pending'

          _.set(
            prev.instances[selector.rootId].items[selector.id].currentContent,
            selector.path,
            after,
          )

          const field = selector.path.split('.')[0]

          const prevChanges = prev.instances[selector.rootId].itemChanges[selector.id]

          prev.instances[selector.rootId].itemChanges[selector.id] = {
            ...prevChanges,
            [field]: true,
          }
        })

        instance.validator(get().instances[selector.rootId])
        instance.updater(get().instances[selector.rootId])
      },

      reset() {
        // used only in tests, cancel all pending debounces
        Object.values(get().instances).forEach((instance) => {
          instance.updater.cancel()
          instance.validator.cancel()
        })

        set({ instances: {} })
      },
    }
  }),
  shallow,
)

const selectItemField = (selector: CommonSelectProps) => {
  return (state: State) => {
    const item = state.instances[selector.rootId].items[selector.id]

    if (!item) {
      return
    }

    return _.get(item.currentContent, selector.path)
  }
}

type FieldMeta = {
  focusKey?: string
  error?: FieldError
}

type OnChange = (e: React.ChangeEvent<HTMLInputElement> | unknown) => void

type UseField = <T>(selector: CommonSelectProps) => readonly [T, OnChange, FieldMeta]

export const useField: UseField = (selector) => {
  const value = useContentVersionState(selectItemField(selector))
  const updateItem = useContentVersionState((state) => state.updateItem)

  const selectorKey = `${selector.id}.${selector.path}`
  const error = useContentVersionState(
    (state) => state.instances[selector.rootId].validation.errors[selectorKey],
  )

  const debounceValue = selector.debounce ?? TIMEOUTS.DEFAULT_DEBOUNCE_MS

  const update = React.useMemo(() => {
    const updateAction: OnChange = (e) => {
      if (e instanceof Event) {
        const target = e.target as HTMLInputElement
        if (target.type === 'checkbox') {
          updateItem(selector, target.checked)
          return target.checked
        }

        updateItem(selector, target.value)

        return target.value
      }
      updateItem(selector, e)

      return e
    }

    if (!debounceValue) return updateAction

    const debounced = _.debounce(updateAction, debounceValue, {
      leading: true,
    })

    if (debounceMapByItem.has(selector.rootId)) {
      debounceMapByItem.get(selector.rootId)!.set(selectorKey, debounced)
    } else {
      debounceMapByItem.set(selector.rootId, new Map([[selectorKey, debounced]]))
    }

    return debounced
  }, [debounceValue, updateItem, selectorKey])

  const meta = React.useMemo(
    () => ({
      focusKey: selectorKey,
      error,
    }),
    [selectorKey, error],
  )

  return [value, update, meta] as const
}

type UseSetField = <T>(
  selector: Omit<CommonSelectProps, 'debounce'>,
  options?: { compare?: (a: T, b: T) => boolean },
) => readonly [(value: T | ((prev: T) => T)) => void]

// This Hook is useful to update the whole object/array without subscribing to changes
export const useSetField: UseSetField = (selector) => {
  const updateItem = useContentVersionState((state) => state.updateItem)

  const update = React.useCallback(
    (e: any) => {
      updateItem(selector, e)
    },
    [selector.id, selector.path, updateItem],
  )

  return [update] as const
}

type UseObjectField = <T>(
  selector: Omit<CommonSelectProps, 'debounce'>,
) => readonly [T, (value: T | ((prev: T) => T)) => void]

export const useObjectField: UseObjectField = (selector) => {
  const value = useContentVersionState(selectItemField(selector))
  const updateItem = useContentVersionState((state) => state.updateItem)

  const update = React.useCallback(
    (e: any) => {
      updateItem(selector, e)
    },
    [selector.id, selector.path, updateItem],
  )

  return [value ?? selector.defaultValue, update] as const
}

export const selectContentVersion = (rootId: string, id: string) => (state: State) => {
  const item = state.instances[rootId].items[id]

  if (!item) {
    return
  }

  return item.currentContent
}

type UseFieldKeys = <T>(
  selector: Omit<CommonSelectProps, 'debounce'>,
) => readonly [string[], (value: T | ((prev: T) => T)) => void]

/**
 * Subscribe to changes in the object/array without subscribing to inner changes
 * It's useful to avoid unnecessary re-renders
 * It returns a memoized list of string keys
 * @example
 * const [ids] = useFieldKeys({ ...props, path: 'content.options' })
 */
export const useFieldKeys: UseFieldKeys = (selector) => {
  const [setField] = useSetField(selector)

  const value = useContentVersionState((state) => {
    const values = selectItemField(selector)(state)

    if (!values || typeof values !== 'object') return []

    if (Array.isArray(values)) {
      return values.map((i) => i.id)
    }

    return Object.keys(values)
  })

  return [value, setField] as const
}

export type Slice = typeof useContentVersionState

// returns true when the item is failed and has no content
export const useHasFailed = (rootId?: string, id?: string) => {
  return useContentVersionState((state) => {
    if (!rootId || !id) {
      return false
    }

    const item = state.instances[rootId]?.items[id]

    if (!item) {
      return false
    }

    return item.status === ItemStatusMap.FAILED && !item.currentContent?.content
  })
}
